import React from "react"
import styled from "styled-components"
import { Each } from "cn-react-helpers"
import { FEATURES } from "../../content"
import { COLORS } from "../../constants"
import { Section, SectionTitle, P, H } from "../../components"

const Description = styled(P)`
  color: ${COLORS.FONT};
  width: 100%;
  max-width: 700px;
  margin: 0 auto 100px auto;
  text-align: center;

  @media(max-width: 600px){
    margin: 0 auto 50px auto;
  }
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  @media(max-width: 600px){
    grid-template-columns: 1fr;
    gap: 20px;
  }
`

const Feature = styled.article`
  padding: 30px;
  border-radius: 10px;
  border: solid ${COLORS.BORDER} 1px;
  display: flex;
  flex-direction: column;

  @media(max-width: 600px){
    flex-direction: row;
    padding: 20px;
  }

  @media(max-width: 350px){
    flex-direction: column;
  }
`

const FeatureInfo = styled.div``

const FeatureTitle = styled(H)`
  text-align: left;
  margin-bottom: 20px;
  color: ${COLORS.TITLE};
`

const FeatureText = styled(P)`
  text-align: left;
  margin-bottom: 0;
  color: ${COLORS.FONT};
  width: 100%;
`

function FeatureIcon({icon, color}){
  const IconWrapper = styled(icon)`
    color: ${color};
    width: 60px;
    height: 60px;
    min-width: 40px;
    margin-bottom: 20px;

    @media(max-width: 600px){
      margin-right: 30px;
    }
  `

  return (
    <IconWrapper />
  )
}

export function Features(){
  return (
    <Section>
      <SectionTitle>
        {FEATURES.TITLE}
      </SectionTitle>

      <Description>
        {FEATURES.DESCRIPTION}
      </Description>

      <Content>
        <Each items={FEATURES.ITEMS}>
          {(ITEM) => (
            <Feature>
              <FeatureIcon icon={ITEM.ICON} color={ITEM.COLOR} />
              <FeatureInfo>
                <FeatureTitle> {ITEM.TITLE} </FeatureTitle>
                <FeatureText small> {ITEM.TEXT} </FeatureText>
              </FeatureInfo>
            </Feature>
          )}
        </Each>
      </Content>
    </Section>
  )
}