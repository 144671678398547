import React, { useRef } from "react"
import styled, {css} from "styled-components"
import { COLORS, IMAGES } from "../../constants"
import {
  SectionTitle,
  Section as LayoutSection,
  Button,
  H,
  P
} from "../../components"

import { MOBBY } from "../../content"
import { Each, LazyImage, LazyIframe } from "cn-react-helpers"
import { FaInfoCircle } from "react-icons/fa"
import { Link } from "gatsby"
import LazyLoad from "react-lazyload"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { darken } from "polished"

const Section = styled(LayoutSection)`
  background-color: ${COLORS.PRIMARY};
`

const Logo = styled.img`
  width: 100%;
  max-width: 300px;
  display: block;
  margin: 0 auto 50px auto;

  @media(max-width: 620px){
    max-width: 200px;
  }
`

const Description = styled(P)`
  width: 100%;
  max-width: 720px;
  margin: 0 auto 70px auto;
  color: ${COLORS.WHITE};
  text-align: center;
`

const Details = styled(Button)`
  margin: 0 auto 70px auto;
`

// Cities

const Cities = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`

const CitiesTitle = styled(H)`
  color: ${COLORS.WHITE};
  border-bottom: solid rgba(255, 255, 255, 0.2) 1px;
  padding-bottom: 30px;
  margin-bottom: 40px;
  text-align: center;
`

const CitiesContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  gap: 40px;

  @media(max-width: 800px){
    gap: 20px;
  }


  @media(max-width: 500px){
    gap: 20px;
    grid-template-columns: 1fr 1fr;
  }
`

const City = styled.a`
  width: 100%;
  display: block;
  text-decoration: none;

  img{
    width: 100%;
    margin-bottom: 10px;
    border-radius: 10px;
    transition: all 0.2s linear 0s;
    border: solid white 2px;

    &:hover{
      box-shadow: 0px 0px 0px 6px rgba(0, 0, 0, 0.4);
    }
  }

  h1{
    font-size: 1em;
    font-weight: normal;
    color: ${COLORS.WHITE};
  }
`

const Map = styled.div`
  overflow: hidden;
  width: 100%;
	display: block;
  position: relative;
`

const MapOverlay = styled.div`
  background: transparent;
  width: 100%;
  height: 800px;
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;

  @media(max-width: 700px){
    height: 400px;
  }

  ${props => props.hide && css`
    display: none;
  `}
`

const Location = styled(LazyIframe)`
  border: none;
  width: 100%;
  height: 800px;
  background-color: ${COLORS.BORDER};
  margin-top: -54px;
  margin-bottom: -2px;

  @media(max-width: 700px){
    height: 400px;
  }
`

const MyTabs = styled(Tabs)`
  background-color: ${COLORS.PRIMARY};

  .react-tabs__tab--selected{
    background-color: ${darken(0.15, COLORS.PRIMARY)} !important;
  }
`

const MyTabList = styled(TabList)`
  display: flex;
`

const MyTab = styled(Tab)`
  display: block;
  width: 100%;
  text-align: center;
  padding: 30px 10px 30px 10px;
  color: white;
  font-size: 1.3em;
  background-color: ${darken(0.05, COLORS.PRIMARY)};
`

export function Mobby(){
  const overlayRef = useRef(null)

  function removeOverlay(){
    overlayRef.current.style.display = "none"
  }

  function showOverlay(){
    overlayRef.current.style.display = "block"
  }

  return(
    <>
      <SectionTitle> Nossas soluções </SectionTitle>
      <Section>
        <LazyLoad>
          <Logo src={IMAGES.MOBBY} />
        </LazyLoad>

        <Description>
          {MOBBY.DESCRIPTION_HOME}
        </Description>

        <Details icon={FaInfoCircle}>
          <Link to="/mobby"> {MOBBY.DETAILS} </Link>
        </Details>

        {/* Cities */}

        <Cities>
          <CitiesTitle> Prefeitura conectadas </CitiesTitle>
          <CitiesContent>
            <Each items={MOBBY.CITIES}>
              {ITEM => (
                <City href={ITEM.LINK}>

                  <LazyImage
                    loader={IMAGES.CITY_LOGO_LOADER}
                    alt={ITEM.NAME}
                    source={ITEM.IMAGE} />

                  <h1> {ITEM.NAME} </h1>
                </City>
              )}
            </Each>
          </CitiesContent>
        </Cities>
      </Section>

      <MyTabs>
        <MyTabList>
          <MyTab>Brasil</MyTab>
          <MyTab>Espanha</MyTab>
        </MyTabList>

        <TabPanel >
          <Map onMouseLeave={showOverlay}>
            <MapOverlay onClick={removeOverlay} ref={overlayRef} />
            <Location source="https://www.google.com/maps/d/embed?mid=1WHV2L5unBd6A6JyDWfFsMyEWFq3ERav-" allowFullScreen=""> </Location>
          </Map>
        </TabPanel>

        <TabPanel>
          <Map onMouseLeave={showOverlay}>
            <MapOverlay onClick={removeOverlay} ref={overlayRef} />
            <Location source="https://www.google.com/maps/d/embed?mid=16bNzacKUZ71vy9X76TB4HF-014JvavSH" allowFullScreen=""> </Location>
          </Map>
        </TabPanel>
      </MyTabs>
    </>
  )
}
