import React from "react"
import { MainLayout } from "../layouts"
import { SEO, Page } from "../components"
import { COLORS } from "../constants"
import {
  Welcome,
  Mission,
  Features,
  Citation,
  Contact,
  Mobby
} from "../partials/home"

import {
  Header,
  Footer,
  Banner
} from "../components"

const IndexPage = () => (
  <MainLayout>
    <SEO themeColor={COLORS.PRIMARY} title="GRT8 - Inovações e Negócios" />
    <Page>
      <Header/>
      <Welcome/>
      <Mission/>
      <Features/>
      <Mobby/>
      <Citation/>
      <Contact/>
      <Footer/>
    </Page>
  </MainLayout>
)

export default IndexPage
