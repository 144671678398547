import React from "react"
import styled from "styled-components"
import {
  Section as LayoutSection,
  SectionTitle,
  Button,
  P
} from "../../components"
import { FaEnvelope } from "react-icons/fa"
import { useForm } from "react-hook-form"
import { Show, LazyIframe } from "cn-react-helpers"
import { COLORS } from "../../constants"
import { CONTACT } from "../../content"

const Section = styled(LayoutSection)`
  background: -webkit-linear-gradient(45deg, ${COLORS.PRIMARY}, ${COLORS.SECONDARY});
`

const Form = styled.form`
  max-width: 600px;
  margin: 0 auto;
`

const Input = styled.input`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4) !important;
  margin-bottom: 20px;
  border: none;
  padding: 10px 20px;
  outline: none;
  font-size: 1.3em;
  font-family: inherit;

  &:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #000 inset
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: #FFFFFF !important;
  }

  &::placeholder{
    color: rgba(255, 255, 255, 0.4);
  }

  &:focus{
    transition: all 0.2s linear 0s;
    border-left: solid white 2px;
  }
`

const TextArea = styled.textarea`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
  border: none;
  padding: 20px 20px;
  outline: none;
  font-size: 1.3em;
  font-family: inherit;

  &::placeholder{
    color: rgba(255, 255, 255, 0.4);
  }

  &:focus{
    transition: all 0.2s linear 0s;
    border-left: solid white 2px;
  }
`

const Location = styled(LazyIframe)`
  border: none;
  width: 100%;
  height: 600px;
  background-color: ${COLORS.BORDER};

  @media(max-width: 700px){
    height: 400px;
  }
`

const ErrorMessage = styled(P)`
  color: #FFF700;
  width: 100%;
  margin-top: -15px;
  margin-bottom: 30px;
  font-weight: 400;
`

const Submit = styled(Button)`
  max-width: 150px;
`

export function Contact(){
  const { register, handleSubmit, errors } = useForm()

  function submit(data){
    console.log(data)
  }

  return(
    <>
      <Section>
        <SectionTitle color="#fff"> {CONTACT.TITLE} </SectionTitle>
        <Form autocomplete="" onSubmit={handleSubmit(submit)}>
          <Input
            autoComplete="off"
            type="text"
            name="name"
            placeholder={CONTACT.NAME_PLACEHOLDER}
            ref={register({ required: true })}
          />

          <Show if={Boolean(errors.name)}>
            <ErrorMessage small>✘ {CONTACT.NAME_ERROR}</ErrorMessage>
          </Show>

          <Input
            autoComplete="off"
            type="text"
            name="email"
            placeholder={CONTACT.EMAIL_PLACEHOLDER}
            ref={register({ required: true })}
          />

          <Show if={Boolean(errors.email)}>
            <ErrorMessage small>✘ {CONTACT.EMAIL_ERROR}</ErrorMessage>
          </Show>

          <TextArea
            autoComplete="off"
            name="content"
            placeholder={CONTACT.CONTENT_PLACEHOLDER}
            ref={register({ required: true })}
          />

          <Show if={Boolean(errors.content)}>
            <ErrorMessage small>✘ {CONTACT.CONTENT_ERROR}</ErrorMessage>
          </Show>

          <Submit type="submit" icon={FaEnvelope}> {CONTACT.SUBMIT} </Submit>
        </Form>
      </Section>
    </>
  )
}
