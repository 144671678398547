import React from "react"
import styled from "styled-components"
import { IMAGES, COLORS } from "../../constants"
import { WELCOME } from "../../content"
import { P } from "../../components"
import { LazyImage } from "cn-react-helpers"

const Container = styled.section`
  padding-top: 60px;
`

const Content = styled.section`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: 80px;
  padding-left: 25px;
  padding-right: 25px;

  @media(max-width: 750px){
    display: block;
  }
`

const WelcomeSymbol = styled(LazyImage)`
  width: 100%;
  max-width: 220px;
  min-width: 220px;
  margin-right: 70px;
  display: block;

  @media(max-width: 750px){
    margin: 0 auto 20px auto;
    max-width: 200px;
    min-width: auto;
  }
`

const WelcomeContent = styled.div`
  @media(max-width: 750px){
    text-align: center;
  }
`

const WelcomeTitle = styled.h1`
  font-size: 4em;
  margin-bottom: 20px;
  font-weight: 300;
  background: -webkit-linear-gradient(45deg, ${COLORS.PRIMARY}, ${COLORS.SECONDARY});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media(max-width: 800px){
    font-size: 3em;
  }
`

const WelcomeText = styled(P)`
  color: ${COLORS.FONT};

  @media(max-width: 750px){
    max-width: 400px;
    margin: 0 auto;
  }
`

const City = styled(LazyImage)`
  margin: 0 auto;
  width: 100%;
  max-width: 700px;
  display: block;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  top: 1px;

  @media(max-width: 750px){
    max-width: 500px;
  }
`

export function Welcome(){
  return (
    <Container>
      <Content>
        <WelcomeSymbol
          loader={IMAGES.BIG_SYMBOL_LOADER}
          source={IMAGES.BIG_SYMBOL}
          alt="symbol"
        />

        <WelcomeContent>
          <WelcomeTitle>
            {WELCOME.TITLE}
          </WelcomeTitle>

          <WelcomeText>
            {WELCOME.DESCRIPTION}
          </WelcomeText>
        </WelcomeContent>
      </Content>

      <City loader={IMAGES.TOP_CITY_LOADER} source={IMAGES.TOP_CITY} alt="city" />
    </Container>
  )
}
