import React from "react"
import styled from "styled-components"
import {
  Effect,
  SectionTitle,
  Section as LayoutSection,
  P
} from "../../components"
import { COLORS } from "../../constants"
import { WELCOME } from "../../content"

const Section = styled(LayoutSection)`
  background-color: ${COLORS.PRIMARY};
  background: linear-gradient(90deg, rgba(111,53,135,1) 0%, rgba(199,40,130,1) 100%);
`

const Description = styled(P)`
  color: white;
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  z-index: 10;

  @media(max-width: 750px){
    max-width: 380px;
  }
`

export function Mission(){
  return (
    <Section>
      <Effect pointColor="#fff" lineColor="#fff"/>

      <SectionTitle color="#fff">
        {WELCOME.BAR_TITLE}
      </SectionTitle>

      <Description>
        {WELCOME.BAR_DESCRIPTION}
      </Description>
    </Section>
  )
}
