import React from "react"
import styled from "styled-components"
import { Effect, Section } from "../../components"
import { COLORS } from "../../constants"
import { CITATION } from "../../content"

const Phrase = styled.h1`
  text-align: center;
  font-size: 3.2em;
  font-weight: 300;
  width: 100%;
  color: ${COLORS.PRIMARY};
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 10;

  @media(max-width: 800px){
    font-size: 1.9em;
    font-weight: 400;
  }
`

export function Citation(){
  return (
    <Section>
      <Effect pointColor={COLORS.SECONDARY} lineColor={COLORS.SECONDARY} />
      <Phrase>
        { CITATION.TEXT }
      </Phrase>
    </Section>
  )
}
